<template>
  <div class="container">
    <vs-button
        style="margin-bottom: 8px"
        primary
        size="small"
        @click="rollback"
    >
      <span><i style="margin-right: 8px;" class="far fa-long-arrow-alt-left"></i></span> ກັບຄືນ
    </vs-button>
    <vs-row>
      <vs-col w="12">
        <vs-alert shadow class="card-feedback" style="position: relative;background-color: #FFFFFF">
          <template #title>
            {{ratingFeedBack.name}}
            <br>
            ລວມຄ່າສະເລ່ຍຈຳນວນຄຳຕິຊົມທັງໝົດ ({{ ratingFeedBack.feedbacks_count }}) ຄັ້ງ
          </template>
          <div class="average-rating">
            <div class="box-rating">
              {{ fixedNumber(ratingFeedBack.feedback_with_rating ? ratingFeedBack.feedback_with_rating.average : 0 ) }}
            </div>
            <div class="Stars"
                 :style="styleRating(ratingFeedBack.feedback_with_rating?ratingFeedBack.feedback_with_rating.average:0)"
                 aria-label="Rating of this product is 2.3 out of 5."></div>
          </div>
          <div style="position: absolute;right: 40px;top: -5px">
            <ul>
              <li>
                <span class="font-star">({{ratingFeedBack.feedback_with_rating?ratingFeedBack.feedback_with_rating.count_score_5:0}} ເທື່ອ)</span>
                <div class="Stars"
                     :style="styleRating(5)"
                     aria-label="Rating of this product is 2.3 out of 5."></div>
              </li>
              <li>
                <span class="font-star">({{ratingFeedBack.feedback_with_rating?ratingFeedBack.feedback_with_rating.count_score_4:0}} ເທື່ອ)</span>
                <div class="Stars"
                     :style="styleRating(4)"
                     aria-label="Rating of this product is 2.3 out of 5."></div>
              </li>
              <li>
                <span class="font-star">({{ratingFeedBack.feedback_with_rating?ratingFeedBack.feedback_with_rating.count_score_3:0}} ເທື່ອ)</span>
                <div class="Stars"
                     :style="styleRating(3)"
                     aria-label="Rating of this product is 2.3 out of 5."></div>
              </li>
              <li>
                <span class="font-star">({{ratingFeedBack.feedback_with_rating?ratingFeedBack.feedback_with_rating.count_score_2:0}} ເທື່ອ)</span>
                <div class="Stars"
                     :style="styleRating(2)"
                     aria-label="Rating of this product is 2.3 out of 5."></div>
              </li>
              <li>
                <span class="font-star">({{ratingFeedBack.feedback_with_rating?ratingFeedBack.feedback_with_rating.count_score_1:0}} ເທື່ອ)</span>
                <div class="Stars"
                     :style="styleRating(1)"
                     aria-label="Rating of this product is 2.3 out of 5."></div>
              </li>
            </ul>
          </div>
        </vs-alert>
      </vs-col>
      <vs-col style="margin-top: 10px;" w="4" v-for="(item,index) in feedBackUserDetail" :key="index">
        <vs-alert shadow class="card-feedback" style="background-color: #FFFFFF">
          <template #title>
            {{ item.user.name }}
          </template>
          <div class="detail-description">
            {{ item.fb_desc }}
          </div>
          <div class="footer-card">
            <span class="icon icon-star">{{ item.fb_score }} <i class="fas fa-star"></i></span>
            <span class="icon" v-if="item && item.feedback_like">{{ item.feedback_like.total_like }} <i
                class="fas fa-thumbs-up"></i></span>
            <span class="icon icon-dislike" v-if="item && item.feedback_like">{{ item.feedback_like.total_dislike }} <i
                class="fas fa-thumbs-down"></i></span>
          </div>
        </vs-alert>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  name: "viewFeedBackDetail",
  data() {
    return {
      user_id: this.$route.params.id,
      feedBackUserDetail: [],
      ratingFeedBack: {},
    }
  },
  methods: {
    fixedNumber(number) {
     if(number){
       return Number.parseFloat(number).toFixed(2);
     }
    },
    styleRating(star) {
      return `--rating:${star};`
    },
    rollback() {
      this.$router.push({
        name: "feedback-user.index"
      }).catch(() => {
      })
    },
    fetchUserFeedBack() {
      this.isLoading = false;
      this.$axios.get(`report-feedbackUserBranch/${this.user_id}`, {
        params: {
          model_type: "user",
        }
      }).then((res) => {
        this.feedBackUserDetail = res.data.data.feedbacks;
        this.ratingFeedBack = res.data.data;
        console.log(this.ratingFeedBack)
      })
    }
  },
  created() {
    this.fetchUserFeedBack();
  }
}
</script>

<style scoped lang="scss">
.detail-description {
  width: 100%;
  height: auto;
  text-align: start;
}

.footer-card {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;

  .icon {
    font-size: 14px;

    i {
      margin-left: 8px;
    }
  }

  .icon-star {
    color: #ff9600;
  }

  .icon-dislike {
    color: #00c2ff;

    i {
      margin-top: 4px;
    }
  }
}

.average-rating {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 80px;
  color: orange;

  .box-rating {
    font-size: 3rem;
    font-weight: 800;
    color: #0a0a0a;
  }
}

.Stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  line-height: 1;
  font-size: 1.4rem;

  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    //background-color: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.font-star{
  font-size: 16px;
  font-weight: 300;
}
</style>